@-webkit-keyframes animateCloud {
  0% {
    margin-left: 105%;
  }
  100% {
    margin-left: -150px;
  }
}

@-moz-keyframes animateCloud {
  0% {
    margin-left: 105%;
  }
  100% {
    margin-left: -150px;
  }
}

@keyframes animateCloud {
  0% {
    margin-left: 105%;
  }
  100% {
    margin-left: -150px;
  }
}

.cloud-1 {
  -webkit-animation: animateCloud 65s linear infinite;
  -moz-animation: animateCloud 65s linear infinite;
  animation: animateCloud 65s linear infinite;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.cloud-2 {
  -webkit-animation: animateCloud 25s linear infinite;
  -moz-animation: animateCloud 25s linear infinite;
  animation: animateCloud 25s linear infinite;
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  transform: scale(0.35);
}

.cloud-3 {
  -webkit-animation: animateCloud 40s linear infinite;
  -moz-animation: animateCloud 40s linear infinite;
  animation: animateCloud 40s linear infinite;
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  transform: scale(0.35);
}

.cloud-4 {
  -webkit-animation: animateCloud 85s linear infinite;
  -moz-animation: animateCloud 85s linear infinite;
  animation: animateCloud 85s linear infinite;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.cloud-5 {
  -webkit-animation: animateCloud 35s linear infinite;
  -moz-animation: animateCloud 35s linear infinite;
  animation: animateCloud 35s linear infinite;
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  transform: scale(0.35);
}

.cloud-6 {
  -webkit-animation: animateCloud 40s linear infinite;
  -moz-animation: animateCloud 40s linear infinite;
  animation: animateCloud 40s linear infinite;
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  transform: scale(0.35);
}

@-webkit-keyframes intialCloud1 {
  from {
    right: 87%;
  }
  to {
    right: 100%;
  }
}

@-moz-keyframes intialCloud1 {
  from {
    right: 87%;
  }
  to {
    right: 100%;
  }
}

@keyframes intialCloud1 {
  from {
    right: 87%;
  }
  to {
    right: 100%;
  }
}

.initial-cloud-1 {
  -webkit-animation: intialCloud1 10s linear;
  -moz-animation: intialCloud1 10s linear;
  animation: intialCloud1 10s linear;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@-webkit-keyframes intialCloud2 {
  from {
    right: 70%;
  }
  to {
    right: 100%;
  }
}

@-moz-keyframes intialCloud2 {
  from {
    right: 70%;
  }
  to {
    right: 100%;
  }
}

@keyframes intialCloud2 {
  from {
    right: 70%;
  }
  to {
    right: 100%;
  }
}

.initial-cloud-2 {
  -webkit-animation: intialCloud2 15s linear;
  -moz-animation: intialCloud2 15s linear;
  animation: intialCloud2 15s linear;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@-webkit-keyframes intialCloud3 {
  from {
    right: 20%;
  }
  to {
    right: 100%;
  }
}

@-moz-keyframes intialCloud3 {
  from {
    right: 20%;
  }
  to {
    right: 100%;
  }
}

@keyframes intialCloud3 {
  from {
    right: 20%;
  }
  to {
    right: 100%;
  }
}

.initial-cloud-3 {
  -webkit-animation: intialCloud3 20s linear;
  -moz-animation: intialCloud3 20s linear;
  animation: intialCloud3 20s linear;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@-webkit-keyframes intialCloud4 {
  from {
    right: 10%;
  }
  to {
    right: 100%;
  }
}

@-moz-keyframes intialCloud4 {
  from {
    right: 10%;
  }
  to {
    right: 100%;
  }
}

@keyframes intialCloud4 {
  from {
    right: 10%;
  }
  to {
    right: 100%;
  }
}

.initial-cloud-4 {
  -webkit-animation: intialCloud4 40s linear;
  -moz-animation: intialCloud4 40s linear;
  animation: intialCloud4 40s linear;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@-webkit-keyframes intialCloud5 {
  from {
    right: 65%;
  }
  to {
    right: 100%;
  }
}

@-moz-keyframes intialCloud5 {
  from {
    right: 65%;
  }
  to {
    right: 100%;
  }
}

@keyframes intialCloud5 {
  from {
    right: 65%;
  }
  to {
    right: 100%;
  }
}

.initial-cloud-5 {
  -webkit-animation: intialCloud5 10s linear;
  -moz-animation: intialCloud5 10s linear;
  animation: intialCloud5 10s linear;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@-webkit-keyframes intialCloud6 {
  from {
    right: 65%;
  }
  to {
    right: 100%;
  }
}

@-moz-keyframes intialCloud6 {
  from {
    right: 65%;
  }
  to {
    right: 100%;
  }
}

@keyframes intialCloud6 {
  from {
    right: 25%;
  }
  to {
    right: 100%;
  }
}

.initial-cloud-6 {
  -webkit-animation: intialCloud6 20s linear;
  -moz-animation: intialCloud6 20s linear;
  animation: intialCloud6 20s linear;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

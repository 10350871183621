@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hover-anime {
    @apply transition ease-in-out duration-500;
  }
}

.sidebar::-webkit-scrollbar {
  width: 0 !important;
}
.sidebar {
  overflow: -moz-scrollbars-none;
}
.sidebar {
  -ms-overflow-style: none;
}

.grecaptcha-badge {
  right: auto !important;
  left: 0;
  width: 70px !important;

  -webkit-transition: width 0.5s ease-in-out !important;
  -moz-transition: width 0.5s ease-in-out !important;
  -o-transition: width 0.5s ease-in-out !important;
  transition: width 0.5s ease-in-out !important;

  &:hover {
    width: 256px !important;
  }
}

.bg-anime {
  -webkit-animation: openDoorLg 1.5s;
  -moz-animation: openDoorLg 1.5s;
  animation: openDoorLg 1.5s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

@keyframes openDoor {
  from {
    background-position: center;
  }
  to {
    background-position: -0.4rem -5.5rem;
  }
}

@keyframes openDoorXl {
  from {
    background-position: center;
  }
  to {
    background-position: -0.188rem -5.5rem;
  }
}

@keyframes openDoorLg {
  from {
    background-position: center;
  }
  to {
    background-position: -0.25rem -4rem;
  }
}

@media only screen and (min-width: 1280px) {
  .bg-anime {
    -webkit-animation: openDoorXl 1.5s;
    -moz-animation: openDoorXl 1.5s;
    animation: openDoorXl 1.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    animation-delay: 1.5s;
  }
}

@media only screen and (min-width: 1536px) {
  .bg-anime {
    -webkit-animation: openDoor 1.5s;
    -moz-animation: openDoor 1.5s;
    animation: openDoor 1.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    animation-delay: 1.5s;
  }
}

.PopoverContent {
  width: var(--radix-popover-trigger-width);
  max-height: var(--radix-popover-content-available-height);
}
